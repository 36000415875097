.container {
  box-shadow: var(--shadow---card);
  padding: 2rem 1.5rem;
  border-radius: var(--card---border-radius);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: var(--grayscale---white);
  height: 100%;
}

.a {
  text-decoration: none !important;
}

.star {
  color: var(--base---green)
}

.reviewBody {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
          line-clamp: 6; 
  -webkit-box-orient: vertical;
}