.container {
  background-repeat: repeat;
}

.title {
  text-align: center;
  font-family: var(--subtitle-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--bold---heading-2);
  letter-spacing: var(--base-letter-spacing);
  color: var(--grayscale---gray-80);
}