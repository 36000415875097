.container {
  color: var(--grayscale---gray-80);
}

.container h1, .container h2, .container h3, .container h4, .container h5, .container h6 {
  font-family: var(--subtitle-font-family);
  font-weight: 700;
  margin-bottom: 1rem !important;
}

.container p {
  font-style: normal;
  font-weight: 400;
}

.container p a {
  color: var(--base---blue)
}

.container ol {
  counter-reset: item;
  list-style: none;
  padding-left: 4.5rem;
}

.container ol li {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  counter-increment: item;
  position: relative;
}

.container ol li:before {
  position: absolute;
  left: -2.5rem;
  margin-right: 1rem;
  content: counter(item);
  background: var(--smooth---green);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.container ul {
  list-style: circle outside url("/images/bullet.png");
  margin-bottom: 1.5rem;
}

.container ul li:not(:last-child) {
  margin-bottom: 1.5rem;
}

.container ul li span {
  position: relative;
  left: .75rem;
}

.container ul li {
  margin-left: 1.5rem;
  padding-left: 1rem;
}

/*.container ul {*/
/*  list-style: none;*/
/*}*/

/*.container ul li {*/
/*  margin-bottom: 1.5rem;*/
/*}*/

/*.container ul li::before {*/
/*  content: "\2022";*/
/*  color: var(--base---blue);*/
/*  font-weight: bold;*/
/*  display: inline-block;*/
/*  width: 1rem;*/
/*}*/

.container blockquote p {
  font-family: var(--subtitle-font-family);
  font-style: normal;
  font-weight: 400;
  letter-spacing: var(--base-letter-spacing);
  font-size: var(--bold---heading-5);

  padding: 0px !important;
  margin: 0px !important;
}

.container figure {
  text-align: center;
}
