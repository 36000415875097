.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
}

.icon {
  margin-bottom: 1rem;
}

.content {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--grayscale---gray-5);
  box-shadow: var(--shadow---card);
  border-radius: var(--card---border-radius);
  color: var(--grayscale---gray-80);
  flex-grow: 1;
}

.title {
  font-family: var(--subtitle-font-family);
  font-size: var(--bold---heading-5);
  color: var(--grayscale---gray-80);
}

@media (max-width: 767px) {
  .icon {
    max-width: 66%;
  }
}
