.container {
  width: 100%;
  /*padding: 4rem 5rem;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  padding-top: 4rem;
}

.image img {
  max-width: 8rem !important;
  max-height: 2rem !important;
}

.title {
  font-family: var(--subtitle-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--bold---heading-1);
  color: var(--grayscale---gray-80);
  letter-spacing: var(--base-letter-spacing);
}

/*@media(max-width: 767px) {*/
/*  .container {*/
/*    padding: 4rem 1rem;*/
/*  }*/
/*}*/
