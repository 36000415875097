.container {
  background-size: 100% auto;
  background-color: #FAFCFC;
  background-position: top;
  background-repeat: no-repeat;
}

.title {
  font-family: var(--subtitle-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--bold---heading-1);
  color: var(--grayscale---gray-80);
  padding-bottom: 2rem;
}

.subtitle {
  font-family: var(--subtitle-font-family);
  font-weight: 700;
  font-size: var(--bold---heading-5);
  letter-spacing: var(--base-letter-spacing);
  color: var(--grayscale---gray-80);
}

.note {
  font-weight: 400;
  font-size: var(--regular---caption-1);
  color: var(--grayscale---gray-80);
  text-align: center;
}

.subscriptionsIncludesList {
  padding-top: 2rem;
  display: flex;
  gap: 8rem;
  justify-content: center;
  align-items: center;
}

.subscriptionsIncludes {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12rem;
  text-align: center;
}

.subscriptionsIncludesText {
  font-weight: 600;
  line-height: 1.25rem;
  color: var(--grayscale---gray-60)
}
.subscriptionsIncludesList {
  gap: 1rem;
}

@media (max-width: 767px) {
  .subscriptionsDivider {
    padding: 1rem
  }
}