.howItWorksSteps {
  display: flex;
  gap: 2rem;
}

.articles {
  display: grid;
  grid-template-columns: repeat(3,minmax(0, 1fr));
  gap: 2rem;
}

.article {
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3,minmax(0, 1fr));
  gap: 3rem;
  padding: 0 5rem 0 5rem;
}

.reviewsContainer {
  padding-bottom: 7rem;
}

@media (max-width: 767px) {
  .articles {
    display: flex;
    flex-direction: column;
  }
  .howItWorksSteps {
    flex-direction: column;
  }
}
