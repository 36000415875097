.container {
  background-color: var(--grayscale---gray-5);
  box-shadow: var(--shadow---card);
  padding: 2rem 1.5rem;
  border-radius: var(--card---border-radius);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.image {
  max-width: 80%;
  max-height: 40px;
}

.body {
  font-family: var(--subtitle-font-family);
  font-weight: 400;
  font-size: var(--bold---heading-5);
  color: var(--grayscale---gray-80);
  line-height: 1.5rem;
}