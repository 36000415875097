.ratingsText {
  font-family: var(--base-font-family);
  color: var(--grayscale---gray-80);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.021px;

  margin-bottom: 46px !important;
}

.ratingsImage {
  height: 62px;
  width: 145px;
  object-fit: cover;
  object-position: bottom left;
  mix-blend-mode: multiply;
}

@media (max-width: 767px) {
  .ratingsText {
    margin-bottom: 48px;
  }
}
