.toggleButton {
  height: 60px;
  width: 172px;
  border: 1px solid transparent !important;
}

.toggleButtonActive {
  background-color: rgb(var(--base---green-rgb)) !important;
}

.toggleButtonInactive {
  background-color: rgb(var(--smooth---green-rgb)) !important;
  color: var(--grayscale---white) !important;
}

.buttonsWrapper {
  max-width: 350px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 992px) {
    margin-bottom: 110px;
  }
}

.discount {
  background-color: var(--grayscale---gray-80);
  border-radius: 40px;
  width: 240px;
  height: 46px;
  text-align: center;
  color: var(--grayscale---white) !important;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: 0.18px;

  position: absolute;
  left: calc(100% + 60px);
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 992px) {
    left: unset;
    top: 115px;
  }
}

.disableDiscount {
  opacity: 0.5;
}

.orderFirstOnMobile {
  order: 1 !important;

  @media (min-width: 992px) {
    order: 0 !important;
  }
}

.orderLastOnMobile {
  order: 2 !important;

  @media (min-width: 992px) {
    order: 0 !important;
  }
}
