

.fullImage {
  padding-top: 75%;
  width: 100%;
  border-radius: var(--card---border-radius) var(--card---border-radius) 0 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position:relative;
}

.image {
  border-radius: var(--card-image---border-radius);
}

.tagContainer {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
}

.title {
  font-family: var(--subtitle-font-family);
  font-weight: bold;
  font-size: var(--bold---heading-5);
  color: var(--grayscale---gray-80);
  letter-spacing: var(--base-letter-spacing);
  line-height: 1.5rem;
}

.body {
  font-size: var(--regular---caption-1);
  color: var(--grayscale---gray-80)
}

.button {
  font-weight: 600;
}