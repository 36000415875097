.heroChecks {
  max-width: 562px;
}
.row {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.icon {
  flex: 0 0 35px;
  margin-right: 16px;
  height: 40px;
}
.text {
  flex: 1 1 100%;
  font-family: var(--base-font-family);
  color: var(--grayscale---gray-80);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.032px;
}

@media (max-width: 767px) {
  .heroChecks {
    max-width: unset;
  }
  .row {
    margin-bottom: 24px;
  }
  .text {
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
  }
}
