.hero {
  padding-top: 80px;
  width: 100%;
  height: 80vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.heroContainer {
  max-width: 1580px;
}

.content {
  background: none;
  position: relative;
}

.title {
  color: var(--grayscale---black);
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: -2.08px;
  text-transform: uppercase;
  margin-bottom: 18px !important;
  max-width: 453px;
}

.button {
  width: 100%;
  max-width: 356px;
}

@media (max-width: 767px) {
  .hero {
    padding-top: 225px;
    padding-left: 0;
  }
  .content {
    background: linear-gradient(
      179.77deg,
      rgba(255, 255, 255, 0) 38.5%,
      #ffffff 75.5%
    );
  }
  .title {
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.192px;
    margin-bottom: 24px !important;
    max-width: unset;
  }

  .button {
    max-width: unset;
  }
}
