.container {
  position: relative;
}

.icon {
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  transform: rotate(-2.26deg);
  box-shadow: var(--shadow---label);
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 80%;
  position:relative;
}

.iconSmall {
  width: 4.5rem;
  height: 4.5rem;
}

.name {
  box-shadow: var(--shadow---label);
  background-color: var(--grayscale---white);
  font-family: var(--subtitle-font-family);
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
}