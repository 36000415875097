.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.boxIcon {
  position: absolute;
  left: 1rem;
  top: 0;
  transform: translate(0, -66%);
}

.fullImage {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 1rem;
  gap: 2rem;
  position: relative;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--grayscale---gray-80);
  font-weight: 600;
  text-decoration: underline !important;
}

.button:hover {
  color: var(--grayscale---gray-80);
}