.container {
  position: relative;
}

.card {
  background-color: var(--grayscale---white) !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 4px solid transparent !important;
  padding: 1.5rem;

  @media (max-width: 767px) {
    order: 1 !important;
  }
}

.totalPayment {
  margin-bottom: 0 !important;
}

.card > div {
  padding: 0 !important;
}

.card hr {
  border: none !important;
  opacity: 1 !important;
  height: 1.5px;
  margin: 1.5rem 0;
}

.cardBlue hr {
  background-color: var(--smooth---blue) !important;
}

.cardBlue {
  border-color: var(--base---blue) !important;
}

.cardBlue button {
  background-color: var(--smooth---blue) !important;
  border-color: var(--smooth---blue) !important;
}

.cardBlue .title {
  color: var(--base---blue) !important;
}

.cardGreen {
  border-color: var(--base---green) !important;
}

.cardGreen hr {
  background-color: var(--smooth---green) !important;
}

.cardGreen .title {
  color: var(--base---green) !important;
}

.cardOrange {
  border-color: var(--base---orange) !important;
}

.cardOrange hr {
  background-color: var(--smooth---orange) !important;
}

.cardOrange button {
  background-color: var(--smooth---orange) !important;
  border-color: var(--smooth---orange) !important;
}

.cardOrange .title {
  color: var(--base---orange) !important;
}

.withBestValueSticker {
  border-radius: 0 0 16px 16px !important;
}

.totalPayment {
  margin-bottom: 0 !important;
}

.bestValueSticker {
  background-color: var(--grayscale---gray-80);
  color: var(--grayscale---white) !important;
  border-radius: 20px;
  padding: 12px 0;
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: 0.18px;
  font-weight: 600;
  text-align: center;
  width: 192px;
}

.checkIcon {
  height: 24px;
  width: 24px;
}

.cross {
  color: var(--warning---red) !important;
}

.check {
  color: var(--base---green) !important;
}

.infiniteSwapsSticker {
  position: absolute;
  top: 120px;
  right: 25px;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.price {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.36px;
  font-weight: 800;
  color: var(--grayscale---gray-90);
  margin-top: -1rem !important;
}

.benefits {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.subscriptionContent {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.18px;
}

.cardGhost {
  visibility: hidden;
  display: none;
}

.cardGhost span,
.cardGhost p,
.cardGhost svg {
  color: var(--grayscale---gray-10) !important;
}

.cardGhost button {
  background-color: var(--grayscale---gray-10) !important;
  border-color: var(--grayscale---gray-10) !important;
  color: var(--grayscale---white) !important;
}
